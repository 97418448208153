import React from "react"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import {
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber, notification,
  Radio,
  Row, Select,
  Slider,
  Switch,
  Typography,
  Upload,
} from "antd"
import moment from "moment"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import { whiteSpaceValidation } from "src/utils/helpers"
import styled from "styled-components"
import {StoriesLinkBuilder} from "src/pages-lib/stories-v2/stories-link-builder";
import {VisibilityProvider} from "../../components/visibility-provider";
import {useUploadFile} from "../../hooks/use-upload-file";
import {API_BASE_MEDIA_URL} from "../../config";
import {getStoriesMediaTypes} from "../../utils/getStoriesMediaTypes";

const { RangePicker } = DatePicker
const { Title } = Typography

export function EditStory({ title, state, initialValues, onClose, onSave }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)
  const [previewImage, setPreviewImage] = React.useState(null)
  const [imageFileList, setImageFileList] = React.useState([]);
  const [storiesFileList, setStoriesFileList] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorLength, setErrorLength] = React.useState(false)
  const [linkBuildState, setLinkBuildState] = React.useState(false)
  const [mediaTypesState, setMediaTypesState] = React.useState({});

  const [form] = Form.useForm()

  const mediaTypes = getStoriesMediaTypes()

  function getImage(image) {
    const file = {
      url: API_BASE_MEDIA_URL + image.path + "/" + image.systemName,
      uid: String(image.id),
      name: image.originalName,
      status: "done",
    }

    return [file]
  }

  function getStories(stories) {
    return stories.map((story, index) => {
      const { image, ...rest } = story; // Убираем image
      const fileId = storiesFileList[index][0]?.uid; // Добавляем проверку, чтобы избежать ошибок
      return {
        ...rest,
        fileId: Number(fileId)
      };
    }).concat(
        initialValues?.stories
            ?.filter((story) => !stories.find((newStory) => newStory.id === story.id))
            .map(({ image, ...rest }) => ({ ...rest, isDeleted: true })) || []
    );
  }

  async function onSubmit(values) {

    if (values.stories?.length > 0) {
      setIsLoading(true)
      setErrorLength(false)
      const id = initialValues?.id

      const data = {
        ...(id && { id, _method: "PUT" }),
        name: values.name,
        description: values.description,
        ...(id && { id }),
        isActive: values.isActive ? 1 : 0,
        startedAt: moment(values.date[0]).format("YYYY-MM-DD"),
        finishedAt: moment(values.date[1]).format("YYYY-MM-DD"),
        sortWeight: values.sortWeight,
        fileId: Number(imageFileList[0].uid)
      }

      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => formData.append(key, value))

      const newStories = getStories(values.stories)
      newStories.forEach((story, index) => {
        Object.entries(story).forEach(([key, value]) => {
          if (key !== "duration") {
            if(key === "actionButton") {
              formData.append(`stories[${index}][${key}]`, value ?? 0)
            } else {
              formData.append(`stories[${index}][${key}]`, value ?? null)
            }
          } else {
            let newVal = String(value)
            if (String(value).length === 1) {
              newVal = "0" + newVal
            }
            formData.append(`stories[${index}][${key}]`, newVal)
          }
        })
      })

      const isPut = data._method === "PUT" && data.id

      setIsLoading(true)
      try {
        await onSave(formData, data.id, isPut)
        form.resetFields()
        handleClose()
      } finally {
        setIsLoading(false)
      }
    } else {
      setErrorLength(true)
    }

  }

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);

      setImageFileList(getImage(initialValues.image));

      const updatedStoriesFileList = {};
      const updatedMediaTypes = {};

      if (initialValues.stories) {
        initialValues.stories.forEach((story, index) => {
          updatedStoriesFileList[index] = getImage(story.image);
          if (story.mediaType) {
            updatedMediaTypes[index] = story.mediaType;
          }
        });
      }

      setStoriesFileList(updatedStoriesFileList);
      setMediaTypesState(updatedMediaTypes);
    }
  }, [initialValues]);

  function handleRemoveStories(name) {
    setStoriesFileList((prev) => {
      const updatedStories = { ...prev };
      delete updatedStories[name];
      return updatedStories;
    });

    setMediaTypesState((prev) => {
      const updatedTypes = { ...prev };
      delete updatedTypes[name];
      return updatedTypes;
    });
  }

  const id = initialValues?.id ?? "new"

  if (!state) {
    return null
  }

  function handleClose() {
    onClose()
    setStoriesFileList({});
    setImageFileList([]);
    form.resetFields()
  }

  async function onPreview(file) {
    if (file instanceof File) {
      file.url = await URL.createObjectURL(file)
    }
    setPreviewImage(file)
  }


  const marks = {
    0: "0",
    15: "15",
    30: "30",
    45: "45",
    60: "60"
  }


  async function onReset() {
    if (initialValues) {
      await form.resetFields()
      await form.setFieldsValue(initialValues)
      return
    }
    form.resetFields()
  }

  function onSelectMediaType(name, value) {
    setMediaTypesState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handleRouteChange(value) {
    if(value.target.value === "innerLink") {
      setLinkBuildState(true)
    }
  }

  async function handleBeforeUpload(file, fieldPath, index = null) {

    const isVideo = file.type.startsWith("video/");
    const isImage = file.type.startsWith("image/");

    if (!isVideo && !isImage) {
      notification.error({ message: "Можно загружать только изображения или видео!" });
      return Upload.LIST_IGNORE;
    }

    const uploadedFile = await useUploadFile(file);
    if (uploadedFile?.id) {
      const newFile = {
        uid: String(uploadedFile.id),
        url: API_BASE_MEDIA_URL + uploadedFile.path + "/" + uploadedFile.systemName,
        name: file.name,
        status: "done",
      };

      if (fieldPath[0] === "image") {
        setImageFileList([newFile]);
        form.setFieldsValue({ image: uploadedFile.id });
      } else if (fieldPath[0] === "stories" && index !== null) {
        setStoriesFileList((prev) => ({
          ...prev,
          [index]: [newFile],
        }));
        form.setFields([{ name: fieldPath, value: uploadedFile.id }]);
      }
    }
    return false;
  }

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "50%"}
      title={title}
      onClose={handleClose}
      open={state}
    >
      <p><strong>Вы можете загрузить изображения не превышающие в сумме 15мб, и не больше 5мб каждое</strong></p>
      <StyledImage
        alt={previewImage?.name || "Превью"}
        preview={{
          visible: previewImage,
          onVisibleChange: () => setPreviewImage(null),
        }}
        src={previewImage?.url || previewImage?.thumbUrl}
      />
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <Divider orientation="left">Обложка</Divider>
        <StyledFormItem
          name={"image"}
          rules={[
            {
              required: true,
              message: "Выберите изображение!",
            },
          ]}
        >
          <Upload
              listType="picture-card"
              maxCount={1}
              accept="image/jpeg,image/png,image/jpg"
              onPreview={onPreview}
              beforeUpload={(file) => handleBeforeUpload(file, ["image"])}
              fileList={Array.isArray(imageFileList) ? imageFileList : []} // ✅ Гарантируем массив
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить изображение</div>
            </div>
          </Upload>
        </StyledFormItem>
        <Divider orientation="left">Настройки</Divider>
        <Row gutter={28}>
          <Col className="gutter-row" span={12}>
            <StyledFormItem
              key={id + "_name"}
              label={"Название группы"}
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Введите название группы!",
                },
                {
                  validator: whiteSpaceValidation,
                },
              ]}
            >
              <Input
                value={form.name}
                type={"text"}
                placeholder={"Название группы"}
              />
            </StyledFormItem>
          </Col>

          <Col className="gutter-row" span={12}>
            <StyledFormItem
              key={id + "_date"}
              label={"Дата публикации"}
              rules={[
                {
                  required: true,
                  message: "Введите дату!",
                },
              ]}
              name="date"
            >
              <RangePicker
                style={{
                  width: "100%",
                }}
                value={form.date}
                format={"YYYY-MM-DD"}
                placeholder={["Начало", "Окончание"]}
              />
            </StyledFormItem>
          </Col>

          <Col className="gutter-row" span={12}>
            <StyledFormItem
              key={id + "_sortWeight"}
              label={"Позиция в списке"}
              name={"sortWeight"}
              rules={[
                {
                  required: true,
                  message: "Введите позицию!",
                },
              ]}
            >
              <InputNumber
                value={form.sortWeight}
                min={1}
                max={250}
                style={{
                  width: "100%",
                }}
                placeholder={"Позиция"}
              />
            </StyledFormItem>
          </Col>

          <Col className="gutter-row" span={12}>
            <StyledFormItem
              key={id + "_isActive"}
              label={"Группа активна"}
              name={"isActive"}
              initialValue={form.isActive ? form.isActive : true}
              rules={[
                {
                  required: true,
                  message: "Please select!",
                },
              ]}
            >
              <Radio.Group buttonStyle="solid" value={form.isActive}>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
              </Radio.Group>
            </StyledFormItem>
          </Col>
        </Row>
        <StyledFormItem
          key={id + "_description"}
          label={"Описание группы"}
          name={"description"}
          rules={[
            {
              required: true,
              message: "Введите описание группы!",
            },
            {
              validator: whiteSpaceValidation,
            },
          ]}
        >
          <Input
            value={form.description}
            type={"text"}
            placeholder={"Описание группы"}
          />
        </StyledFormItem>
        <Divider orientation="left">Контент</Divider>
        { errorLength && (<>
          <div style={{ color: "red" }}>Добавьте хотя-бы 1-ну сторис!</div>
        </>)}
        <Form.List name="stories">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...field }, index) => (
                <React.Fragment key={`RowTitle_${index}`}>
                  <RowTitle level={4}>
                    История {key + 1}{" "}
                    {fields.length > 0 ? (
                        <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ marginLeft: "12px" }}
                            onClick={() => {
                              handleRemoveStories(name)

                              remove(name);
                            }}
                        />
                    ) : null}
                  </RowTitle>
                  <Form.Item required={false} key={key}>
                    <StyledFormItem
                        key={id + "_" + name + "_mediaType"}
                        label={"Тип медиа"}
                        name={[name, "mediaType"]}
                        rules={[
                          {
                            required: true,
                            message: "Выберите тип!",
                          },
                        ]}
                    >
                      <Select
                          placeholder={"Выберите тип"}
                          options={mediaTypes}
                          onSelect={(value) => onSelectMediaType(name, value)}
                      />
                    </StyledFormItem>
                    {mediaTypesState[name] === "picture" && (
                        <StyledFormItem
                            name={[name, "image"]}
                            rules={[{ required: true, message: "Выберите изображение!" }]}
                        >
                          <Upload
                              listType="picture-card"
                              maxCount={1}
                              accept="image/jpeg,image/png,image/jpg"
                              onPreview={onPreview}
                              beforeUpload={(file) => handleBeforeUpload(file, ["stories", name, "image"], name)}
                              fileList={Array.isArray(storiesFileList[name]) ? storiesFileList[name] : []}
                          >
                            <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Загрузить изображение</div>
                            </div>
                          </Upload>
                        </StyledFormItem>
                    )}
                    {mediaTypesState[name] === "video" && (
                        <StyledFormItem
                            name={[name, "image"]}
                            rules={[{ required: true, message: "Загрузите видео!" }]}
                        >
                          <Upload
                              listType={"text"}
                              maxCount={1}
                              accept="video/mp4"
                              beforeUpload={(file) =>
                                  handleBeforeUpload(file, ["stories", name, "video"], name)
                              }
                              fileList={Array.isArray(storiesFileList[name]) ? storiesFileList[name] : []}
                          >
                            <Button icon={<PlusOutlined />}>Загрузить видео (.mp4)</Button>
                          </Upload>
                        </StyledFormItem>
                    )}
                    <StyledFormItem
                      {...field}
                      key={id + "_name"}
                      label={"Название сторис"}
                      name={[name, "name"]}
                      rules={[
                        {
                          message: "Введите название!",
                          required: true,
                        },
                        {
                          validator: whiteSpaceValidation,
                        },
                      ]}
                    >
                      <Input type={"text"} placeholder={"Название"} />
                    </StyledFormItem>
                    <RowFlex>
                      <div>{"Добавить кнопку: "}</div>
                      <Form.Item
                        {...field}
                        label={"Добавить кнопку"}
                        name={[name, "actionButton"]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </RowFlex>
                    <Row gutter={28}>
                      <Col className="gutter-row" span={12}>
                        <StyledFormItem
                          {...field}
                          key={id + "_actionName"}
                          label={"Название кнопки"}
                          name={[name, "actionName"]}
                          rules={[
                            (formInstance) => ({
                              message: "Введите название кнопки!",
                              required:
                                formInstance.getFieldValue("stories")?.[key]
                                  ?.actionButton,
                            }),
                            {
                              validator: whiteSpaceValidation,
                            },
                          ]}
                          dependencies={["actionButton"]}
                        >
                          <Input type={"text"} placeholder={"Название кнопки"} />
                        </StyledFormItem>
                      </Col>

                      <Col className="gutter-row" span={12}>
                        <StyledFormItem
                            {...field}
                            key={id + "_route"}
                            label={"Роутинг"}
                            rules={[
                              (formInstance) => ({
                                message: "Выберите роутинг!",
                                required:
                                formInstance.getFieldValue("stories")?.[key]
                                    ?.actionButton,
                              }),
                            ]}
                            name={[name, "route"]}
                        >
                          <Radio.Group onChange={handleRouteChange}>
                            <Radio value={"outherLink"}>Внешний роутинг</Radio>
                            <Radio value={"innerLink"}>Внутренний роутинг</Radio>
                          </Radio.Group>
                        </StyledFormItem>

                      </Col>

                      <Col className="gutter-row" span={12}>
                        <StyledFormItem
                            {...field}
                            key={id + "_link"}
                            label={"Ссылка"}
                            name={[name, "link"]}
                            rules={[
                              (formInstance) => ({
                                message: "Введите ссылку",
                                required:
                                formInstance.getFieldValue("stories")?.[key]
                                    ?.actionButton,
                              }),
                              {
                                validator: whiteSpaceValidation,
                              },
                            ]}
                        >
                          <Input type={"text"} placeholder={"Ссылка"} />
                        </StyledFormItem>

                      </Col>
                      <Col className="gutter-row" span={12}>
                        <StyledFormItem
                          {...field}
                          key={id + "_duration"}
                          label={"Время отображения, сек"}
                          rules={[
                            {
                              required: true,
                              message: "Введите время отображения!",
                            },
                          ]}
                          name={[name, "duration"]}
                        >
                          <Slider marks={marks} step={15} max={60} min={0} />
                        </StyledFormItem>
                      </Col>
                    </Row>
                  </Form.Item>
                </React.Fragment>
              ))}

              <Row gutter={28}>
                <Col className="gutter-row" span={12}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Добавить
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <ButtonRow>
          <ButtonCol>
            <Button danger onClick={onReset} icon={<RotateClockwiseSvg />}>
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button
              icon={<SaveSvg />}
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>

      <VisibilityProvider visible={linkBuildState}>
        <StoriesLinkBuilder
            title={"Новая ссылка"}
            onCloseDrawer={setLinkBuildState}
        />
      </VisibilityProvider>

    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledImage = styled(Image)`
  display: none;
`

const RowFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`

const RowTitle = styled(Title)`
  margin-bottom: 20px;
`
