import {
  ArticleCategories,
  Articles,
  Clients,
  EventCategories,
  Events,
  LiveLoveWork,
  Login,
  Points,
  PointProducts,
  Products,
  ProductCategories,
  ProductsGroups,
  Orders,
  PromoCodes,
  Promotion,
  PromotionsCategories,
  Stories,
  StoriesV2,
  ProductConstructor,
  Certificates,
  Push,
  OrdersHoldPayment,
  DeliverySetting,
  PaymentAccounts,
  Onboarding,
  Achievements,
  Faq,
  UserStatuses,
  UserStatusGroups,
  Rewards,
  ProductVideo,
  PartnerDiscounts
} from "src/pages";


export const UNAUTH_PATHS = [
  {
    path: "/dashboard/auth",
    isDefault: true,
    component: Login
  },
  {
    path: "/healthcheck",
    isDefault: false,
    component: () => <div></div>
  },
];

export const UNAUTH_MAIN_PAGE = UNAUTH_PATHS.find((path) => path.isDefault)?.path ?? "";

export const PRIVATE_PATHS = [
  {
    path: "/dashboard/achievements",
    isDefault: true,
    component: Achievements
  },
  {
    path: "/dashboard/clients",
    isDefault: true,
    component: Clients
  },
  {
    path: "/dashboard/promo-codes",
    isDefault: false,
    component: PromoCodes
  },
  {
    path: "/dashboard/partner-discounts",
    isDefault: false,
    component: PartnerDiscounts
  },
  {
    path: "/dashboard/live-love-work",
    isDefault: false,
    component: LiveLoveWork
  },
  {
    path: "/dashboard/stories",
    isDefault: false,
    component: Stories
  },
  {
    path: "/dashboard/stories-v2",
    isDefault: false,
    component: StoriesV2
  },
  {
    path: "/dashboard/certificates",
    isDefault: false,
    component: Certificates
  },
  {
    path: "/dashboard/articles",
    isDefault: false,
    component: Articles
  },
  {
    path: "/dashboard/article-categories",
    isDefault: false,
    component: ArticleCategories
  },
  {
    path: "/dashboard/promotions",
    isDefault: false,
    component: Promotion
  },
  {
    path: "/dashboard/promotion-categories",
    isDefault: false,
    component: PromotionsCategories
  },
  {
    path: "/dashboard/events",
    isDefault: false,
    component: Events
  },
  {
    path: "/dashboard/event-categories",
    isDefault: false,
    component: EventCategories
  },
  {
    path: "/dashboard/products",
    isDefault: false,
    component: Products
  },
  {
    path: "/dashboard/products-video",
    isDefault: false,
    component: ProductVideo
  },
  {
    path: "/dashboard/product-categories",
    isDefault: false,
    component: ProductCategories
  },
  {
    path: "/dashboard/products-groups",
    isDefault: false,
    component: ProductsGroups
  },
  {
    path: "/dashboard/points",
    isDefault: false,
    component: Points
  },
  {
    path: "/dashboard/push",
    isDefault: false,
    component: Push
  },
  {
    path: "/dashboard/payment-accounts",
    isDefault: false,
    component: PaymentAccounts
  },
  {
    path: "/dashboard/delivery-setting",
    isDefault: false,
    component: DeliverySetting
  },
  {
    path: "/dashboard/orders",
    isDefault: false,
    component: Orders
  },
  {
    path: "/dashboard/order-hold-payment",
    isDefault: false,
    component: OrdersHoldPayment
  },
  {
    path: "/dashboard/points-product",
    isDefault: false,
    component: PointProducts
  },
  {
    path: "/dashboard/product-constructor",
    isDefault: false,
    component: ProductConstructor
  },
  {
    path: "/dashboard/onboarding",
    isDefault: false,
    component: Onboarding
  },
  {
    path: "/dashboard/faq",
    isDefault: false,
    component: Faq
  },
  {
    path: "/dashboard/user-status-groups",
    isDefault: false,
    component: UserStatusGroups
  },
  {
    path: "/dashboard/user-statuses",
    isDefault: false,
    component: UserStatuses
  },
  {
    path: "/dashboard/rewards",
    isDefault: false,
    component: Rewards
  },
];

export const BASIC_PRIVATE_PATH = PRIVATE_PATHS.find((path) => path.isDefault)?.path ?? "";
